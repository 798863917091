import { useState } from "react";
import { useParams } from "react-router-dom";
import MatchLayout from "./components/RecommendationLayout";
import styled from "@emotion/styled";
import { Button, Descriptions, Image, Input, InputNumber, List, Modal, Typography, Select } from "antd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  RecommendationShortFallUser,
  getRecommendationShortFall,
  getRecommendationShortFallPartnerUsers,
  postRecommendationShortFallPartnerUsers,
  getRecommendationPartnerFilterCategory
} from "../../utils/api/recommend";
import useToggle from "../../common/hooks/useToggle";
import Loading from "../../common/components/Loading";
import UserImageCarousel from "../user/components/UserImageCarousel";

const RecommendationShorFall = () => {

  const [filter, setFilter] = useState({
    name: "",
    minAge: 20,
    maxAge: 60,
    minHeight: 130,
    maxHeight: 200,
    appearanceRating: "",
    careerRating: "",
    smokingStatus: "",
    drinkingStatus: "",
    address: "",
    addressSubGroup: "",
    refereeName: "",
  });

  const onChangeFilter = (value: string | number, key: string) => {
    if (key === "address") {
      setFilter((prev) => ({
        ...prev,
        [key]: value as string,
        addressSubGroup: "",
      }));
      return;
    }
    setFilter((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const params = useParams<{ id: string }>();


  const qc = useQueryClient();
  const [onRecommendModal, toggleRecommendModal] = useToggle();
  const { data } = useQuery({
    queryKey: ["recommendation-short-fail", filter],
    queryFn: () => getRecommendationShortFall({
      ...filter
    }),
  });

  const [clickedUserId, setClickedUserId] = useState(0);

  const { data: recommendedPartnerFilter } = useQuery({
    queryKey: ["recommendationPartnerFilterCategory", params.id],
    queryFn: () => getRecommendationPartnerFilterCategory(),
  });

  const {
    data: canRecommendationUsers,
    isLoading: isCanRecommendationUserLoading,
  } = useQuery({
    queryKey: ["recommendation-short-fail-recommendation", clickedUserId],
    queryFn: () => getRecommendationShortFallPartnerUsers(clickedUserId),
    enabled: !!clickedUserId,
  });
  
  const [isLoading, setIsLoading] = useState(false);

  const { mutate } = useMutation({
    mutationFn: (partnerUserId: number) =>
      postRecommendationShortFallPartnerUsers({
        userId: clickedUserId,
        partnerUserId,
      }),
    onSuccess: () => {
      qc.invalidateQueries({ queryKey: ["recommendation-short-fail"] });
      qc.invalidateQueries({
        queryKey: ["recommendation-short-fail-recommendation", clickedUserId],
      });
      setTimeout(() => {
        setIsLoading(false);
      }, 800);
    },
    onError: () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 800);
    },
    onMutate: () => {
      setIsLoading(true);
    },
  });

  if (!recommendedPartnerFilter) return <></>;



  // const mutate = (userId: number) => {};

  return (
    <MatchLayout>
      <Paper>
        <Typography.Title level={2}>
          유저 추천 큐 12명 미만 유저 관리
        </Typography.Title>
        <Flex
          style={{
            padding: "12px",
          }}
        >
          <div>
            <Typography.Title level={5}>외모 등급</Typography.Title>
            <Select
              defaultValue=""
              placeholder="외모 레벨"
              style={{ width: 150 }}
              value={filter.appearanceRating}
              onChange={(value) => onChangeFilter(value, "appearanceRating")}
              options={
                [
                { value: "", label: "전체" },
                ...recommendedPartnerFilter.appearanceRatings.map((data) => ({
                  value: data,
                  label: data,
                })),
              ]
            }
            />
          </div>
          <div>
            <Typography.Title level={5}>능력 등급</Typography.Title>
            <Select
              defaultValue=""
              placeholder="능력 등급"
              style={{ width: 150 }}
              value={filter.careerRating}
              onChange={(value) => onChangeFilter(value, "careerRating")}
              options={[
                { value: "", label: "전체" },
                ...recommendedPartnerFilter.careerRatings.map((data) => ({
                  value: data,
                  label: data,
                })),
              ]}
            />
          </div>
          <div>
            <Typography.Title level={5}>지역 (시,도)</Typography.Title>
            <Select
              defaultValue=""
              placeholder="지역 (시,도)"
              style={{ width: 150 }}
              value={filter.address}
              onChange={(value) => onChangeFilter(value, "address")}
              options={[
                { value: "", label: "전체" },
                { value: "서울", label: "서울" },
                { value: "수도권", label: "수도권" },
                { value: "비수도권", label: "비수도권" }
              ]}
            />
          </div>
          <div>
            <Typography.Title level={5}>지역 (시,군,구)</Typography.Title>
            <Select
              defaultValue=""
              placeholder="지역 (시,군,구)"
              style={{ width: 150 }}
              disabled={!filter.address}
              value={filter.addressSubGroup}
              onChange={(value) => onChangeFilter(value, "addressSubGroup")}
              options={
                filter.address
                  ? [
                      { value: "", label: "전체" },
                      ...recommendedPartnerFilter.addressGroupMap[
                        filter.address as "서울" | "수도권" | "비수도권"
                      ].addressSubGroups.map((data) => ({
                        value: data,
                        label: data,
                      })),
                    ]
                  : []
              }
            />
          </div>
          <div>
            <Typography.Title level={5}>흡연</Typography.Title>
            <Select
              defaultValue=""
              placeholder="흡연"
              style={{ width: 150 }}
              value={filter.smokingStatus}
              onChange={(value) => onChangeFilter(value, "smokingStatus")}
              options={[
                { value: "", label: "전체" },
                ...recommendedPartnerFilter.smokingStatuses.map((data) => ({
                  value: data,
                  label: data,
                })),
              ]}
            />
          </div>
          <div>
            <Typography.Title level={5}>음주</Typography.Title>
            <Select
              defaultValue=""
              placeholder="음주"
              style={{ width: 150 }}
              value={filter.drinkingStatus}
              onChange={(value) => onChangeFilter(value, "drinkingStatus")}
              options={[
                { value: "", label: "전체" },
                ...recommendedPartnerFilter.drinkingStatuses.map((data) => ({
                  value: data,
                  label: data,
                })),
              ]}
            />
          </div>
        </Flex>
        
        <Flex
          style={{
            marginBottom: "36px",
            gap: "36px",
          }}
        >
          <div>
            <Typography.Title level={5}>이름</Typography.Title>
            <Input
              value={filter.name}
              onChange={(e) => onChangeFilter(e.target.value, "name")}
            />
          </div>
          <div>
            <Typography.Title level={5}>키 범위</Typography.Title>
            <InputNumber
              min={130}
              max={200}
              value={filter.minHeight}
              onChange={(value) => onChangeFilter(value as number, "minHeight")}
            />
            cm ~{" "}
            <InputNumber
              min={130}
              max={200}
              defaultValue={200}
              value={filter.maxHeight}
              onChange={(value) => onChangeFilter(value as number, "maxHeight")}
            />
            cm
          </div>
          <div>
            <Typography.Title level={5}>나이 범위</Typography.Title>
            <InputNumber
              min={20}
              max={60}
              defaultValue={20}
              value={filter.minAge}
              onChange={(value) => onChangeFilter(value as number, "minAge")}
            />
            세 ~{" "}
            <InputNumber
              min={20}
              max={60}
              defaultValue={60}
              value={filter.maxAge}
              onChange={(value) => onChangeFilter(value as number, "maxAge")}
            />
            세
          </div>
          <div>
            <Typography.Title level={5}>추천인</Typography.Title>
            <Select
              defaultValue=""
              placeholder="흡연"
              style={{ width: 150 }}
              value={filter.refereeName}
              onChange={(value) => onChangeFilter(value, "refereeName")}
              options={[
                { value: "", label: "전체" },
                ...recommendedPartnerFilter.refereeNames.map((data) => ({
                  value: data,
                  label: data,
                })),
              ]}
            />
          </div>
        </Flex>

        <List
          pagination={{
            pageSize: 5,
            align: "start",
          }}
          bordered
          dataSource={data}
          header={<ListHeader>추천 받을 유저</ListHeader>}
          renderItem={(
            {
              address,
              addressSubGroup,
              careerDetail,
              drinkingStatus,
              finalAcademic,
              gender,
              height,
              idOkPartnerSameAge,
              income,
              name,
              smokingStatus,
              userImages,
              partnerHeightRange,
              partnerUnderAge,
              partnerUpperAge,
              religion,
              univName,
              yearOfBirth,
              userId,
              userProfileId,
              careerSubGroup,
              careerType,
            }: RecommendationShortFallUser,
            index
          ) => (
            <List.Item
              style={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "12px",
              }}
              onClick={() => setClickedUserId(userId)}
            >
              <FlexCloumn>
                <Flex>
                  <Flex>
                    <UserImageCarousel images={userImages} />
                  </Flex>
                  <Descriptions column={1}>
                    <Descriptions.Item label="이름">{name}</Descriptions.Item>
                    <Descriptions.Item label="성별">{gender}</Descriptions.Item>
                    <Descriptions.Item label="나이">
                      {yearOfBirth}
                    </Descriptions.Item>
                    <Descriptions.Item label="키">{height}</Descriptions.Item>
                  </Descriptions>
                  <Descriptions column={1}>
                    <Descriptions.Item label="사는곳">
                      {address} /{addressSubGroup}
                    </Descriptions.Item>
                    <Descriptions.Item label="최종학력">
                      {finalAcademic} <br /> {univName}
                    </Descriptions.Item>
                    <Descriptions.Item label="흡연">
                      {smokingStatus}
                    </Descriptions.Item>
                    <Descriptions.Item label="술">
                      {drinkingStatus}
                    </Descriptions.Item>
                  </Descriptions>
                  <Descriptions column={1}>
                    <Descriptions.Item label="종교">
                      {religion}
                    </Descriptions.Item>
                    <Descriptions.Item label="직장">
                      {careerDetail}
                    </Descriptions.Item>
                    <Descriptions.Item label="직업군">
                      {careerType}
                    </Descriptions.Item>
                    <Descriptions.Item label="연봉">
                      {income || "입력 전"}
                    </Descriptions.Item>
                  </Descriptions>
                  <Descriptions column={1}>
                    <Descriptions.Item label="원하는 키">
                      {partnerHeightRange}
                    </Descriptions.Item>

                    <Descriptions.Item label="원하는 연하 나이">
                      {partnerUnderAge}
                    </Descriptions.Item>
                    <Descriptions.Item label="동갑">
                      {idOkPartnerSameAge}
                    </Descriptions.Item>
                    <Descriptions.Item label="원하는 연상 나이">
                      {partnerUpperAge}
                    </Descriptions.Item>
                  </Descriptions>
                </Flex>
                {/* <Button type="primary" onClick={toggleRecommendModal}>
                  추천 해주러 가기
                </Button> */}
                <Button type="primary">
                  <a href={`/recommend/${userProfileId}`}>추천 해주러 가기</a>
                </Button>
              </FlexCloumn>
            </List.Item>
          )}
        />
      </Paper>
      <Modal
        width={1248}
        title="추천 가능 유저 리스트"
        open={onRecommendModal}
        onCancel={toggleRecommendModal}
        footer={[<Button onClick={toggleRecommendModal}>닫기</Button>]}
      >
        <List
          bordered
          dataSource={canRecommendationUsers}
          loading={isCanRecommendationUserLoading}
          header={<ListHeader>추천 가능 유저</ListHeader>}
          renderItem={(
            {
              address,
              addressSubGroup,
              careerDetail,
              drinkingStatus,
              finalAcademic,
              gender,
              height,
              idOkPartnerSameAge,
              income,
              name,
              smokingStatus,
              userImages,
              partnerHeightRange,
              partnerUnderAge,
              partnerUpperAge,
              religion,
              univName,
              yearOfBirth,
              userId,
              userProfileId,
              careerSubGroup,
              careerType,
            }: RecommendationShortFallUser,
            index
          ) => (
            <List.Item
              style={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "12px",
              }}
            >
              {isLoading ? (
                <Loading />
              ) : (
                <FlexCloumn>
                  <Flex>
                    <Flex>
                      <Image src={userImages[0]} width={100} />
                      <Image src={userImages[1]} width={100} />
                      <Image src={userImages[2]} width={100} />
                    </Flex>
                    <Descriptions column={1}>
                      <Descriptions.Item label="이름">{name}</Descriptions.Item>
                      <Descriptions.Item label="성별">
                        {gender}
                      </Descriptions.Item>
                      <Descriptions.Item label="나이">
                        {yearOfBirth}
                      </Descriptions.Item>
                      <Descriptions.Item label="키">{height}</Descriptions.Item>
                    </Descriptions>
                    <Descriptions column={1}>
                      <Descriptions.Item label="사는곳">
                        {address} /{addressSubGroup}
                      </Descriptions.Item>
                      <Descriptions.Item label="최종학력">
                        {finalAcademic} <br /> {univName}
                      </Descriptions.Item>
                      <Descriptions.Item label="흡연">
                        {smokingStatus}
                      </Descriptions.Item>
                      <Descriptions.Item label="술">
                        {drinkingStatus}
                      </Descriptions.Item>
                    </Descriptions>
                    <Descriptions column={1}>
                      <Descriptions.Item label="종교">
                        {religion}
                      </Descriptions.Item>
                      <Descriptions.Item label="직장">
                        {careerDetail}
                      </Descriptions.Item>
                      <Descriptions.Item label="직업군">
                        {careerType}
                      </Descriptions.Item>
                      <Descriptions.Item label="연봉">
                        {income || "입력 전"}
                      </Descriptions.Item>
                    </Descriptions>
                    <Descriptions column={1}>
                      <Descriptions.Item label="원하는 키">
                        {partnerHeightRange}
                      </Descriptions.Item>

                      <Descriptions.Item label="원하는 연하 나이">
                        {partnerUnderAge}
                      </Descriptions.Item>
                      <Descriptions.Item label="동갑">
                        {idOkPartnerSameAge}
                      </Descriptions.Item>
                      <Descriptions.Item label="원하는 연상 나이">
                        {partnerUpperAge}
                      </Descriptions.Item>
                    </Descriptions>
                  </Flex>
                  <Button type="primary" onClick={() => mutate(userId)}>
                    추천 해주기
                  </Button>
                </FlexCloumn>
              )}
            </List.Item>
          )}
        />
      </Modal>
    </MatchLayout>
  );
};

const Paper = styled.div`
  padding: 16px;
  background-color: white;
  border-radius: 12px;
`;

const ListHeader = styled.div`
  font-weight: 700;
  font-size: 16px;
`;

const Flex = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
`;

const FlexCloumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: flex-start;
`;

export default RecommendationShorFall;
