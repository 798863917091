import React, { PropsWithChildren } from "react";
import { Layout, Menu, MenuProps } from "antd";
import { Link, useLocation } from "react-router-dom";

const { Header, Content, Footer } = Layout;
function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

type MenuItem = Required<MenuProps>["items"][number];
const items: MenuItem[] = [
  getItem(<Link to="/recommend">추천 큐 목록 조회</Link>, "/recommend"),
  getItem(
    <Link to="/recommend/recommendation">추천 큐 12명 미만 유저 조회</Link>,
    "/recommend/recommendation"
  ),
];

const RecommendationLayout = ({ children }: PropsWithChildren) => {
  const location = useLocation();
  const path = location.pathname;
  return (
    <Layout className="layout">
      <Header>
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={[path]}
          items={items}
        />
      </Header>
      <Content style={{ padding: "20px 50px" }}>
        <div className="site-layout-content" style={{ background: "white" }}>
          {children}
        </div>
      </Content>
      <Footer style={{ textAlign: "center" }}>주선왕 ©2024</Footer>
    </Layout>
  );
};

export default RecommendationLayout;
