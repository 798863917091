import {  Descriptions } from "antd";
import styled from "@emotion/styled";
import { useState } from 'react';
import UserImageCarousel from "../../user/components/UserImageCarousel"
import { PartnerUser } from "../../../utils/api/recommend";

interface RecommendedProfileCardProps {
    userId: string // 현재 조회하고 있는 유저의 id
    partner: PartnerUser; // 현재 조회하고 있는 유저에게 추천해준 유저
    apiKey: any; // 현재 조회하고 있는 유저의 프로필 id
}


const RecommendedProfileCard = ({ userId, partner, apiKey }: RecommendedProfileCardProps) => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    const toggleInvisibility = () => {
        setIsVisible(false);
    }


    return (
        <Section>
            <ProfileImages>
                <UserImageCarousel images={partner.userProfileImages} />
            </ProfileImages>

            <FlexColumn>
                <div onClick={toggleVisibility}>
                    <Descriptions column={2} title="유저 요약 정보">
                        <Descriptions.Item label="이름">{partner.name}</Descriptions.Item>
                        <Descriptions.Item label="나이">{partner.age}</Descriptions.Item>
                        <Descriptions.Item label="키">{partner.height}</Descriptions.Item>
                        <Descriptions.Item label="직장">{partner.careerDetail}</Descriptions.Item>
                        <Descriptions.Item label="외모 등급">{partner.appearanceRating}</Descriptions.Item>
                        <Descriptions.Item label="능력 등급">{partner.overallSocialRating}</Descriptions.Item>
                        {/*<Descriptions.Item label="mbti">{partner.mbti || "입력 전"}</Descriptions.Item>*/}
                        {/*<Descriptions.Item label="종교">{partner.religion}</Descriptions.Item>*/}
                        {/*<Descriptions.Item label="도시">{partner.address}</Descriptions.Item>*/}
                        {/*<Descriptions.Item label="거주지">{partner.addressSubGroup}</Descriptions.Item>*/}
                        {/*<Descriptions.Item label="흡연 / 음주 여부">{partner.smokingStatus} / {partner.drinkingStatus}</Descriptions.Item>*/}
                        {/*<Descriptions.Item label="추천인">{partner.refereeName || "입력 전"}</Descriptions.Item>*/}
                        {/*<Descriptions.Item label="유입 경로">{partner.funnel || "입력 전"}</Descriptions.Item>*/}
                    </Descriptions>
                </div>
                {
                    isVisible &&

                    <FlexColumn onClick={toggleInvisibility}>
                        <Descriptions column={2} title="유저 세부 정보">
                            <Descriptions.Item label="직장명">{partner.careerSubGroup}</Descriptions.Item>
                            <Descriptions.Item label="직업군">{partner.careerType}</Descriptions.Item>
                        {/*    <Descriptions.Item label="연봉">{partner.income || "입력 전"}</Descriptions.Item>*/}
                        {/*    <Descriptions.Item label="자기소개">{partner.appealPoint}</Descriptions.Item>*/}
                        {/*</Descriptions>*/}
                        {/*<Descriptions column={2} title="선호 조건">*/}
                        {/*    <Descriptions.Item label="연상 선호">*/}
                        {/*        {partner.partnerUpperAge}*/}
                        {/*    </Descriptions.Item>*/}
                        {/*    <Descriptions.Item label="연하 선호">*/}
                        {/*        {partner.partnerUnderAge}*/}
                        {/*    </Descriptions.Item>*/}
                        {/*    <Descriptions.Item label="동갑 선호">*/}
                        {/*        {partner.idOkPartnerSameAge || "입력 전"}*/}
                        {/*    </Descriptions.Item>*/}
                        {/*</Descriptions>*/}

                        {/*<Descriptions column={2}>*/}
                        {/*    <Descriptions.Item label="선호 키">*/}
                        {/*        {partner.partnerHeightRange}*/}
                        {/*    </Descriptions.Item>*/}
                        {/*    <Descriptions.Item label="요청 사항">*/}
                        {/*        {partner.userRequest || "입력 전"}*/}
                        {/*    </Descriptions.Item>*/}
                        {/*</Descriptions>*/}

                        {/*<Descriptions column={2} title={"기타정보"}>*/}
                        {/*    <Descriptions.Item label="최종 학력">*/}
                        {/*        {partner.finalAcademic}*/}
                        {/*    </Descriptions.Item>*/}
                        {/*    <Descriptions.Item label="학교명">*/}
                        {/*        {partner.univName}*/}
                        {/*    </Descriptions.Item>*/}

                        {/*    <Descriptions.Item label="연봉 레벨">*/}
                        {/*        {partner.incomeRating}*/}
                        {/*    </Descriptions.Item>*/}
                        {/*    <Descriptions.Item label="학교 레벨">*/}
                        {/*        {partner.univRating}*/}
                        {/*    </Descriptions.Item>*/}
                        {/*    <Descriptions.Item label="특징">*/}
                        {/*        {partner.referencePoint}*/}
                        {/*    </Descriptions.Item>*/}

                        {/*    <Descriptions.Item label="이상형">{partner.idealType || "입력 전"}</Descriptions.Item>*/}
                        </Descriptions>

                    </FlexColumn>

                }

            </FlexColumn>

        </Section>
    );
}

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: flex-start;
`;

const Section = styled.div`
  padding : 8px;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  margin-bottom : 8px;
`;

const ProfileImages = styled.div`
    display: flex;
    overflow-x: auto;
    margin-bottom : 8px;
    margin-top : 8px;
`;

export default RecommendedProfileCard;