import "./App.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import SideBar from "./common/components/SideBar";
import { Outlet, useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Button } from "antd";
import userStorage from "./utils/userStorage";
import { useEffect, useState } from "react";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 0,
      refetchOnWindowFocus: false,
    },
  },
});

const handleLogin = () => {
  // 구글 로그인 화면으로 이동시키기
  window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_GOOGLE_AUTH_REDIRECT_URI}&response_type=code&scope=email`;
};

function App() {
  const [isLogin, setIsLogin] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (!!userStorage.get()) setIsLogin(true);
  }, [location.pathname]);

  return (
    <QueryClientProvider client={queryClient}>
      {isLogin ? (
        <Layout>
          <SideBar />
          <Outlet />
        </Layout>
      ) : (
        <NonLoginLayout>
          <div>
            <Button danger size="large" onClick={handleLogin}>
              구글 로그인
            </Button>
          </div>
          <Outlet />
        </NonLoginLayout>
      )}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

const Layout = styled.div`
  display: flex;
`;

const NonLoginLayout = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export default App;
