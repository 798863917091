import apiClient from "./apiClient";

export interface RecommendedUsers {
  userProfileId: number;
  userId: number;
  name: string;
  careerType: string;
  careerDetail: string;
  careerSubGroup: string;
  finalAcademic: string;
  univName: string;
  age: number;
  height: string;
  address: string;
  addressSubGroup: string;
  income: number;
  religion: string;
  partnerUnderAge: string;
  partnerUpperAge: string;
  idOkPartnerSameAge: string;
  partnerHeightRange: string[];
  smokingStatus: string;
  drinkingStatus: string;
  gender: string;
  userImages: string[];
  appealPoint: string;
  referencePoint: string[];
  userRequest: string;
  careerRating: string;
  univRating: string;
  appearanceRating: string;
  incomeRating: string;
  idealType: string;
  refereeName: string;
  funnel: string;
  recommendationCount: number;
  kakaoAppUserId: string;
  mbti: string;
  refundUser: boolean;
}

export interface RecommendationPartner {
  appearanceRating: string;
  careerDetail: string;
  careerRating: string;
  careerSubGroup: string;
  careerType: string;
  gender: string;
  income: number;
  incomeRating: string;
  name: string;
  univRating: string;
  userImages: string[];
  userProfileId: number;
  address: string;
  addressSubGroup: string;
  religion: string;
  partnerUnderAge: string;
  partnerUpperAge: string;
  idOkPartnerSameAge: string;
  partnerHeightRange: string[];
  smokingStatus: string;
  drinkingStatus: string;
  appealPoint: string;
  referencePoint: string[];
  userRequest: string;
  idealType: string;
  height: string;
  age: number;
  finalAcademic: string;
  univName: string;
  userId: number;
  twoWayRecommendationEnabled: boolean;
  refereeName: string;
  funnel: string;
  mbti: string;
}

export interface RecommendedPartner {
  appearanceRating: string;
  careerDetail: string;
  careerRating: string;
  careerSubGroup: string;
  careerType: string;
  gender: string;
  income: number;
  incomeRating: string;
  name: string;
  univRating: string;
  userImages: string[];
  userProfileId: number;
  address: string;
  addressSubGroup: string;
  religion: string;
  partnerUnderAge: string;
  partnerUpperAge: string;
  idOkPartnerSameAge: string;
  partnerHeightRange: string[];
  smokingStatus: string;
  drinkingStatus: string;
  appealPoint: string;
  referencePoint: string[];
  userRequest: string;
  idealType: string;
  height: string;
  age: number;
  finalAcademic: string;
  univName: string;
  userId: number;
  bothRecommendation: boolean;
  refereeName: string;
  funnel: string;
  mbti: string;
  refundUser: boolean;
}

export interface Pagination {
  totalPages: number;
  totalElements: number;
  pageSize: number;
  pageNumber: number;
  hasNext: boolean;
  hasPrevious: boolean;
}

export interface PartnerUser {
  id: string;
  name: string;
  gender: 'MALE' | 'FEMALE';
  age : number;
  height: number;
  userProfileImages: string[];
  appearanceRating: string;
  overallSocialRating: string;
  careerDetail: string;
  careerSubGroup: string;
  careerType: 'PRIVATE_ENTERPRISE' | string;
}

export interface Recommendation {
  recommendationId: string;
  recommendationLevel: string;
  recommendationType: string;
  partnerUser: PartnerUser;
}

export interface RecommendedUser {
  id: string;
  name: string;
  gender: '남자' | '여자';
  age : number;
  height: number;
  partnerHeightRange: number[];
  partnerUnderAge: number;
  partnerUpperAge: number;
  userProfileImages: string[];
  appearanceRating: string;
  overallSocialRating: string;
  careerDetail: string;
  careerSubGroup: string;
  careerType: 'PRIVATE_ENTERPRISE' | string;
  recommendations: Recommendation[];
}

export interface RecommendedUsersResponse {
  status: string;
  data: {
    pagination: Pagination;
    contents: RecommendedUser[];
  };
}

interface GetRecommendedUsersParameter {
  gender?: string;
  name?: string;
  minAge?: number;
  maxAge?: number;
  minHeight?: number;
  maxHeight?: number;
  appearanceRating?: string;
  careerRating?: string;
  smokingStatus?: string;
  drinkingStatus?: string;
  address?: string;
  addressSubGroup?: string;
  recommendationCount?: number;
  pageSize?: number;
  pageNumber?: number;
}

export const getRecommendedUsers = async (params: GetRecommendedUsersParameter) : Promise<RecommendedUsersResponse>=> {
  const filteredParams = Object.entries(params)
      .filter(
          ([_, value]) =>
              value !== undefined && value !== null && value !== "" && value !== 0
      )
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

  const response = await apiClient.get<RecommendedUsersResponse>(
      `/v1/admin/recommended-users`,
      {
        params: filteredParams
      }
  );

  return response.data;
};

export const getRecommendedUserById = async (parameter: {
  "user-profile-id": number;
}) => {
  const res = await apiClient.get<RecommendedUsers>(
    `/v1/admin/${parameter["user-profile-id"]}/recommended-user`
  );
  return res.data;
};

interface getRecommendationPartnersParameter {
  userId: number;
  name?: string;
  minAge?: number;
  maxAge?: number;
  minHeight?: number;
  maxHeight?: number;
  appearanceRating?: string;
  careerRating?: string;
  smokingStatus?: string;
  drinkingStatus?: string;
  address?: string;
  addressSubGroup?: string;
  refereeName?: string;
}

export const getRecommendationPartners = async (
  params: getRecommendationPartnersParameter
) => {
  const filteredParams = Object.entries(params)
    .filter(
      ([_, value]) =>
        value !== undefined && value !== null && value !== "" && value !== 0
    )
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

  const res = await apiClient.get<RecommendationPartner[]>(
    `/v1/admin/recommendation-partner`,
    { params: filteredParams }
  );

  return res.data;
};

export const getRecommendedPartners = async (profileId: number) => {
  const res = await apiClient.get<RecommendedPartner[]>(
    `/v1/admin/${profileId}/recommended-users`
  );
  return res.data;
};

export interface RecommendationShortFallUser {
  userProfileId: number;
  userId: number;
  name: string;
  careerType: string;
  careerDetail: string;
  careerSubGroup: string;
  finalAcademic: string;
  univName: string;
  yearOfBirth: string;
  height: string;
  address: string;
  addressSubGroup: string;
  income: number;
  religion: string;
  partnerUnderAge: string;
  partnerUpperAge: string;
  idOkPartnerSameAge: string;
  partnerHeightRange: string[];
  smokingStatus: string;
  drinkingStatus: string;
  gender: string;
  userImages: string[];
}

interface getRecommendationShortFallParameter {
  name?: string;
  minAge?: number;
  maxAge?: number;
  minHeight?: number;
  maxHeight?: number;
  appearanceRating?: string;
  careerRating?: string;
  smokingStatus?: string;
  drinkingStatus?: string;
  address?: string;
  addressSubGroup?: string;
  refereeName?: string;
}

export const getRecommendationShortFall = async (
  params: getRecommendationShortFallParameter
) => {
  const filteredParams = Object.entries(params)
    .filter(
      ([_, value]) =>
        value !== undefined && value !== null && value !== "" && value !== 0
    )
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

  const res = await apiClient.get<RecommendationShortFallUser[]>(
    `/v1/admin/recommendation-shortfall`,
    { params: filteredParams }
  );
  return res.data;
};

export const getRecommendationShortFallPartnerUsers = async (
  userId: number
) => {
  const res = await apiClient.get<RecommendationShortFallUser[]>(
    `/v1/admin/recommendation-shorfall-partner`,
    {
      params: { userId },
    }
  );

  return res.data;
};

interface PostRecommendationShortFallPartnerUsersBody {
  userId: number;
  partnerUserId: number;
}

export const postRecommendationShortFallPartnerUsers = async (
  body: PostRecommendationShortFallPartnerUsersBody
) => {
  await apiClient.post(`/v1/admin/recommendation-shortfall`, body);
};

export const postBothRecommendationShortFallPartnerUsers = async (
  body: PostRecommendationShortFallPartnerUsersBody
) => {
  await apiClient.post(`/v1/admin/both-recommendation-shortfall`, body);
};

interface DeleteRecommendationBody {
  userId: number;
  partnerUserId: number;
}

export const deleteOneWayRecommendation = async (
  params: DeleteRecommendationBody
) => {
  await apiClient.delete(`/v1/admin/recommendation`, { params });
};

export const deleteBothWayRecommendation = async (
  params: DeleteRecommendationBody
) => {
  await apiClient.delete(`/v1/admin/both-recommendation`, { params });
};

export interface RecommendationPartnerFilter {
  appearanceRatings: string[];
  careerRatings: string[];
  smokingStatuses: string[];
  drinkingStatuses: string[];
  religions: string[];
  addressGroupMap: {
    서울: {
      address: string;
      addressSubGroups: string[];
    };
    수도권: {
      address: string;
      addressSubGroups: string[];
    };
    비수도권: {
      address: string;
      addressSubGroups: string[];
    };
  };
  refereeNames: string[];
}

export const getRecommendationPartnerFilterCategory = async () => {
  const res = await apiClient.get<RecommendationPartnerFilter>(
    `/v1/admin/recommendation-partner-filter-category`
  );
  return res.data;
};
