import React from "react";
import {
  FileSearchOutlined,
  ReadOutlined,
  UserOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Menu } from "antd";

import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";

// @NOTE tk https://ant.design/components/menu#components-menu-demo-inline
type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem(
    "대시보드",
    "dashboard",
    <FileSearchOutlined />,
    [
      getItem(<Link to="/users">회원</Link>, "/users", <UserOutlined />),
      getItem(
        <Link to="/recommendations">추천</Link>,
        "/recommendations",
        <ReadOutlined />
      ),
      getItem(<Link to="/matching">매칭</Link>, "/matching", <TeamOutlined />),
    ],
    "group"
  ),
];

const SideBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <SideBarContainer>
      <Logo onClick={() => navigate("/")}>주선왕</Logo>

      <Menu
        defaultSelectedKeys={[location.pathname]}
        mode="vertical"
        theme="light"
        items={items}
      />
    </SideBarContainer>
  );
};

const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  background-color: #000;
`;

const SideBarContainer = styled.div`
  flex: none;
  position: relative;
  width: 256px;
  min-height: 100vh;
  height: 100%;
  border-inline-end: 1px solid rgba(5, 5, 5, 0.06);
`;

export default SideBar;
