import {useQuery} from "@tanstack/react-query";
import {useParams} from "react-router-dom";
import {
    getRecommendedUsers,
    RecommendedUsersResponse, RecommendedUser
} from "../../utils/api/recommend";
import MatchLayout from "./components/RecommendationLayout";
import styled from "@emotion/styled";
import {
    Descriptions,
    Typography
} from "antd";

import RecommendedProfileCard from "./components/RecommendedProfileCard";
import UserImageCarousel from "../user/components/UserImageCarousel";
import {useState} from "react";

interface QueryResult {
    contents: RecommendedUser[];
}

const RecommendationDetail = () => {
    // params 여기서 id 는 유저 프로필 아이디임
    const params = useParams<{ id: string }>();
    const [filter] = useState({
        name: "",
        userId: params.id  // 초기 상태에 id 추가
    });

    const {data: recommendationUserQueryResult} = useQuery<RecommendedUsersResponse, Error, QueryResult>({
        queryKey: ["matching-users", filter],
        queryFn: () =>
            getRecommendedUsers({
                ...filter
            }),
        select: (data) => ({
            contents: data.data.contents
        })
    });


    return (
        <MatchLayout>
            <Paper>
                <Typography.Title level={2}>추천 큐 상태 조회</Typography.Title>
                <Flex
                    style={{
                        alignItems: "flex-start",
                    }}
                >
                    <Section>
                        <Flex
                            style={{
                                paddingTop: "32px",
                                alignItems: "flex-start",
                            }}
                        >
                            <FlexColumn>
                                <UserImageCarousel
                                    images={recommendationUserQueryResult?.contents[0].userProfileImages ?? []}/>
                                <Descriptions column={1} title="유저 정보">
                                    <Descriptions.Item
                                        label="이름">{recommendationUserQueryResult?.contents[0].name}</Descriptions.Item>
                                    <Descriptions.Item
                                        label="성별">{recommendationUserQueryResult?.contents[0].gender}</Descriptions.Item>
                                    <Descriptions.Item label="외모 등급">
                                        {recommendationUserQueryResult?.contents[0].appearanceRating}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="능력 등급">
                                        {recommendationUserQueryResult?.contents[0].overallSocialRating}
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label="키">{recommendationUserQueryResult?.contents[0].height}cm</Descriptions.Item>
                                    <Descriptions.Item
                                        label="선호 키">{recommendationUserQueryResult?.contents[0].partnerHeightRange.at(0)}cm
                                        ~ {recommendationUserQueryResult?.contents[0].partnerHeightRange.at(-1)}cm</Descriptions.Item>
                                    <Descriptions.Item
                                        label="나이">{recommendationUserQueryResult?.contents[0].age}세</Descriptions.Item>
                                    <Descriptions.Item label="선호 나이">
                                        {Number(recommendationUserQueryResult?.contents[0]?.age ?? 0) -
                                            Number(recommendationUserQueryResult?.contents[0]?.partnerUnderAge ?? 0)}세 ~
                                        {Number(recommendationUserQueryResult?.contents[0]?.age ?? 0) +
                                            Number(recommendationUserQueryResult?.contents[0]?.partnerUpperAge ?? 0)}세
                                    </Descriptions.Item>

                                </Descriptions>
                                <Descriptions column={1}></Descriptions>
                                <Descriptions column={1}>
                                    <Descriptions.Item label="직장">
                                        {recommendationUserQueryResult?.contents[0].careerDetail}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="직장명">
                                        {recommendationUserQueryResult?.contents[0].careerSubGroup}
                                    </Descriptions.Item>

                                    <Descriptions.Item label="직업군">
                                        {recommendationUserQueryResult?.contents[0].careerType}
                                    </Descriptions.Item>
                                </Descriptions>
                            </FlexColumn>
                        </Flex>
                    </Section>
                    <Section>
                        <Typography.Title level={2}>
                            {" "}
                            첫 추천 받은 유저 목록 :{" "}
                            {recommendationUserQueryResult?.contents[0].recommendations && recommendationUserQueryResult?.contents[0].recommendations.length} /
                            8{" "}
                        </Typography.Title>
                        <RecommendedPartnerSection>
                            {recommendationUserQueryResult?.contents[0].recommendations && (
                                <>
                                    {recommendationUserQueryResult?.contents[0].recommendations?.map((recommendation) => (
                                        <RecommendedProfileCard
                                            key={recommendation.recommendationId}
                                            userId={recommendationUserQueryResult?.contents[0].id}
                                            partner={recommendation.partnerUser}
                                            apiKey={params.id}
                                        />
                                    ))}
                                </>
                            )}
                        </RecommendedPartnerSection>
                    </Section>
                </Flex>
            </Paper>
        </MatchLayout>
    );
};

const Paper = styled.div`
    padding: 16px;
    background-color: white;
    border-radius: 12px;
`;

const Section = styled.div`
    height: auto;
    width: 500px;
    border: 1px solid #d9d9d9;
    border-radius: 12px;
    padding: 12px;
`;

const Flex = styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;
`;

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    justify-content: flex-start;
`;


const RecommendedPartnerSection = styled.div`
    max-height: 1000px;
    overflow-y: auto;
`;

export default RecommendationDetail;