import {useState} from "react";
import MatchLayout from "./components/RecommendationLayout";
import styled from "@emotion/styled";
import {useQuery} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import {
    Button,
    Descriptions,
    List,
    Input,
    Segmented,
    Typography
} from "antd";
import {
    RecommendedUsersResponse,
    RecommendedUser,
    Recommendation,
    getRecommendedUsers, Pagination
} from "../../utils/api/recommend";
import UserImageCarousel from "../user/components/UserImageCarousel";

interface QueryResult {
    contents: RecommendedUser[];
    pagination: Pagination;
}

interface GroupedRecommendations {
    [key: string]: Recommendation[];
}

const RecommendationHome = () => {
        const {Search} = Input;
        const navigate = useNavigate();

        const [filter, setFilter] = useState({
            gender: "MALE",
            name: "",
            page: 1,        // 현재 페이지
            pageSize: 10    // 페이지당 아이템 수
        });


        const onChangeFilter = (value: string | number, key: string) => {
            if (key === "address") {
                setFilter((prev) => ({
                    ...prev,
                    [key]: value as string,
                    addressSubGroup: "",
                }));
                return;
            }
            setFilter((prev) => ({
                ...prev,
                [key]: value,
            }));
        };

        const {data: recommendationUserQueryResult} = useQuery<RecommendedUsersResponse, Error, QueryResult>({
            queryKey: ["matching-users", filter],
            queryFn: () =>
                getRecommendedUsers({
                    ...filter
                }),
            select: (data) => ({
                contents: data.data.contents,
                pagination: data.data.pagination
            })
        });

        const onScrollTop = () => {
            window.scrollTo(0, 0);
        };

        // 페이지 변경 핸들러 추가
        const handlePageChange = (page: number) => {
            setFilter(prev => ({
                ...prev,
                page
            }));
            onScrollTop();
        };

        return (
            <MatchLayout>
                <Paper>
                    <Typography.Title level={2}>유저 추천 큐 관리</Typography.Title>
                    <div
                        style={{
                            marginBottom: 12,
                            width: "500px",
                        }}
                    >
                        <Search
                            placeholder="유저명을 검색해주세요"
                            allowClear
                            enterButton="검색"
                            size="large"
                            onChange={(e) => onChangeFilter(e.target.value, "name")}
                            value={filter.name}
                            onSearch={(value) => navigate(`/recommend?search=${value}`)}
                        />
                    </div>
                    <FilterBox>
                      <FlexColumn>
                        <Flex
                          style={{
                            padding: "16px",
                          }}
                        >
                          <div>
                            <Typography.Title level={5}>성별</Typography.Title>
                            <Segmented
                              value={filter.gender}
                              onChange={(value) => {
                                setFilter((prev) => ({
                                  ...prev,
                                  gender: value as "MALE" | "FEMALE",
                                }));
                              }}
                              options={[
                                {
                                  label: "남자",
                                  value: "MALE",
                                },
                                {
                                  label: "여자",
                                  value: "FEMALE",
                                },
                              ]}
                            />
                          </div>
                        </Flex>
                      </FlexColumn>
                    </FilterBox>

                    <List
                        pagination={{
                            current: filter.page,
                            pageSize: filter.pageSize,
                            total: recommendationUserQueryResult?.pagination.totalElements || 0,
                            onChange: handlePageChange,
                            align: "start",
                        }}
                        bordered
                        dataSource={recommendationUserQueryResult?.contents}
                        header={<ListHeader>추천 받은 유저</ListHeader>}
                        renderItem={({
                                         id,
                                         name,
                                         gender,
                                         userProfileImages,
                                         appearanceRating,
                                         overallSocialRating,
                                         careerDetail,
                                         careerSubGroup,
                                         recommendations,
                                     }) => {
                            // recommendations 데이터 그룹화
                            const grouped = recommendations.reduce<GroupedRecommendations>((acc, curr) => {
                                const level = curr.recommendationLevel;
                                acc[level] = acc[level] || [];
                                acc[level].push(curr);
                                return acc;
                            }, {});
                            return (
                                <List.Item>
                                    <FlexColumn>
                                        <Flex>
                                            <Flex>
                                                <UserImageCarousel images={userProfileImages}/>
                                            </Flex>

                                            <Descriptions column={1}>
                                                <Descriptions.Item
                                                    label="전체 첫 추천 갯수">{Array.from(recommendations.keys()).length}</Descriptions.Item>
                                                <Descriptions.Item
                                                    label="동급 첫 추천 갯수">{grouped['SAME_LEVEL']?.length || 0}</Descriptions.Item>
                                                <Descriptions.Item
                                                    label="미끼 첫 추천 갯수">{grouped['UPPER_LEVEL']?.length || 0}</Descriptions.Item>
                                            </Descriptions>
                                            <Descriptions column={1}>
                                                <Descriptions.Item label="이름">{name}</Descriptions.Item>
                                                <Descriptions.Item
                                                    label="성별">{gender}</Descriptions.Item>
                                            </Descriptions>
                                            <Descriptions column={1}>
                                                <Descriptions.Item label="외모 레벨">
                                                    {appearanceRating}
                                                </Descriptions.Item>
                                                <Descriptions.Item label="직장">
                                                    {careerDetail}
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Descriptions column={1}>
                                                <Descriptions.Item label="직업 레벨">
                                                    {overallSocialRating}
                                                </Descriptions.Item>
                                                <Descriptions.Item label="직장선택지">
                                                    {careerSubGroup}
                                                </Descriptions.Item>
                                            </Descriptions>
                                        </Flex>
                                        <Flex>
                                            <Button type="primary">
                                                <a href={`/recommendations/${id}`}>추천 관리 페이지로 이동</a>
                                            </Button>
                                        </Flex>
                                    </FlexColumn>
                                </List.Item>)
                        }}/>
                </Paper>
            </MatchLayout>
        );
    }
;

const Paper = styled.div`
    padding: 16px;
    background-color: white;
    border-radius: 12px;
`;

const FilterBox = styled.div`
    width: 100%;
    display: flex;
    gap: 24px;
    margin: 16px 0;
    padding: 16px;
`;

const ListHeader = styled.div`
    font-weight: 700;
    font-size: 16px;
`;

const Flex = styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;
`;

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    justify-content: flex-start;
`;

export default RecommendationHome;
