import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import User from "./pages/user/User";
import RecommendationHome from "./pages/recommend/RecommendationHome";
import Appearance from "./pages/user/Appearance";
import Ability from "./pages/user/Career";
import Univ from "./pages/user/Univ";
import RecommendationShorFall from "./pages/recommend/RecommendationShorFall";
import RecommendationDetail from "./pages/recommend/RecommendationDetail";
import MatchingHome from "./pages/matching/MatchingHome";
import MatchingDetail from "./pages/matching/MatchingDetail";
import GoogleLogin from "./pages/oauth/GoogleLogin";
import AuthGuardLayout from "./pages/oauth/components/AuthGuardLayout";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <AuthGuardLayout>
        <App />
      </AuthGuardLayout>
    ),
    children: [
      {
        path: "/oauth/google-redirect",
        element: <GoogleLogin />,
      },
      {
        path: "/users",
        element: <User />,
      },
      {
        path: "/users/appearance",
        element: <Appearance />,
      },
      {
        path: "/users/career",
        element: <Ability />,
      },
      {
        path: "/users/univ",
        element: <Univ />,
      },
      {
        path: "/recommendations",
        element: <RecommendationHome />,
      },
      {
        path: "/recommendations/:id",
        element: <RecommendationDetail />,
      },
      {
        path: "/recommendations/shortFall",
        element: <RecommendationShorFall />,
      },
      {
        path: "/matching",
        element: <MatchingHome />,
      },
      {
        path: "/matching/:id",
        element: <MatchingDetail />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
